<template>
  <div style="background: #e35049;color: #000;height: 100%;">
    <van-nav-bar title="我的拓客券详情" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hest">
      <div class="heds">
        <img class="tkimg" src="../../assets/img/tkxiangqin.png" alt />
        <div class="mian">
          <div class="mian_a">{{couponName}}</div>
          <div class="mian_r" v-if="isok && couponitem.balanceType ==0 && couponitem.type ==7">
            适用门店：
            <span
              style="color: #000;"
            >{{couponitem.balanceGarageName?couponitem.balanceGarageName:'暂未指定'}}</span>
            <span class="cxiu" @click="tobiangen">修改</span>
          </div>
          <div class="mian_r">卡券说明:</div>
          <div class="mian_s">{{useExplain}}</div>
          <div class="mian_r" v-if="isok">
            <span class="zhang">1张</span>
            有效期：{{startDate}}~{{validityDate}}
          </div>
          <div v-if="isok && givenCoupon == 1">
            <div class="zhuan" v-if="sellRule==1">转赠码</div>
            <div class="qy_code_wrapper" v-if="isok">
              <div v-if="couponitem.type ==7 && couponitem.balanceType ==0">
                <vue-qr
                  v-if="couponitem.balanceGarageName"
                  :text="downloadData.url"
                  :margin="2"
                  colorDark="#000"
                  colorLight="#fff"
                  :dotScale="1"
                  :logoSrc="downloadData.icon"
                  :logoScale="0.2"
                  :size="210"
                ></vue-qr>
              </div>
              <div v-else>
                <vue-qr
                  :text="downloadData.url"
                  :margin="2"
                  colorDark="#000"
                  colorLight="#fff"
                  :dotScale="1"
                  :logoSrc="downloadData.icon"
                  :logoScale="0.2"
                  :size="210"
                ></vue-qr>
              </div>
            </div>
          </div>
          <div class="dingdan" v-if="isok && couponitem.balanceGarageName">
            {{couponNumber}}
            <span class="fuzi" @click="copy">复制</span>
          </div>
          <div
            class="zhidin"
            v-if="isok && !couponitem.balanceGarageName && couponitem.balanceType ==0 && couponitem.type ==7"
          >指定适用门店后，显示转赠码</div>
          <div v-if="!isok" style="height: 20px;"></div>
          <div class="guizhe">使用规则</div>
          <div class="gzname" v-if="isok">
            <span class="dian">·</span>
            本券生效时间：{{startDate}}
          </div>
          <div class="gzname" v-else>
            <span class="dian">·</span>
            本券生效时间: 付款日起{{validity}}天
          </div>
          <div class="gzname">
            <span class="dian">·</span>本券仅可在周一至周五使用
          </div>
          <div class="gzname">
            <span class="dian">·</span>本券需在线上预约后方可使用
          </div>
          <div class="gzname">
            <span class="dian">·</span>
            <span v-if="shangjia.id == 484">本券适用驿车驹·宏开店旗下自营门店</span>
            <span v-else>本券适用胜创车业旗下自营门店</span>
          </div>
          <div class="gzname">
            <span class="dian">·</span>本优惠券不与其他优惠券同享
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueQr from "vue-qr";
export default {
  components: {
    vueQr
  },
  data() {
    return {
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: 0,
      couponitem: null,
      couponNumber: this.$route.query.couponNumber,
      useExplain: this.$route.query.useExplain,
      validity: this.$route.query.validity,
      couponName: this.$route.query.couponName,
      startDate: this.$route.query.startDate,
      validityDate: this.$route.query.validityDate,
      sellPrice: this.$route.query.sellPrice,
      sellRule: this.$route.query.sellRule,
      isok: this.$route.query.isok,
      givenCoupon: this.$route.query.givenCoupon,
      type: this.$route.query.type,
      id: this.$route.query.id,
      downloadData: {
        url:
          "?couponNumber=" +
          this.$route.query.couponNumber +
          "&sellRule=" +
          this.$route.query.sellRule +
          "&uid=" +
          this.$route.query.uid +
          "&id=" +
          this.$route.query.id +
          "&type=" +
          this.$route.query.type
      }
    };
  },
  methods: {
    //复制链接成功
    copy() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      var http = this.couponNumber;
      input.setAttribute("value", http);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast.success("复制成功！");
      }
      document.body.removeChild(input);
    },
    //变更套餐
    tobiangen() {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false,
          title: "请选择指定后的门店",
          showisok: "tuoke_coupon_details",
          id: this.couponitem.id
        }
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    this.couponitem = JSON.parse(sessionStorage.getItem("couponitem"));

    console.log(couponitem);
  }
};
</script>
<style lang="less" scoped>
.hest {
  margin-top: 46px;
  overflow: hidden;
}
.heds {
  position: relative;
  font-size: 13px;
  color: #666;
}
.mian_a {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.mian_r {
  margin-top: 6px;
  line-height: 20px;
  overflow: hidden;
}
.cxiu {
  float: right;
  color: #1989fa;
}
.mian_s {
  // margin-top: 5px;
  padding-left: 26px;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.zhidin {
  color: red;
  font-size: 12px;
  text-align: center;
}
.mian {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 34px;
  width: 100%;
}
.zhuan {
  text-align: center;
  color: #000;
  font-size: 15px;
  margin-top: 10px;
}
.gzname {
  padding-left: 4px;
}
.guizhe {
  font-size: 14px;
  margin-top: 10px;
  font-weight: bold;
}
.tkimg {
  width: 100%;
  display: block;
}
.jinq {
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
}
.qy_code_wrapper {
  min-height: 210px;
  // width: 80%;
  // margin: auto;
  text-align: center;
  margin-top: 5px;
}
.dian {
  font-weight: bold;
  margin-right: 5px;
}
.zhang {
  display: inline-block;
  border: 1px solid #e35049;
  color: #e35049;
  line-height: 16px;
  font-size: 12px;
  padding: 0 2px;
  border-radius: 3px;
}
.dingdan {
  text-align: center;
  color: #000;
  font-size: 14px;
  line-height: 20px;
}
.fuzi {
  color: #1989fa;
  margin-left: 10px;
}
</style>